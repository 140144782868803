

@font-face { 
  font-family: "Barlow-Bold";
src: url('fonts/Barlow-Bold.ttf') format('truetype');

}
@font-face { 
  font-family: "Barlow-Regular";
src: url('fonts/Barlow-Regular.ttf') format('truetype');

}

.pp1 {
  display: flex;
  flex-direction: column;
  justify-content: space-around;}

.i1{
  width: 60%;
  margin-left: 15%;
}

.i2{
  width: 50%;
  margin-left: 3%;
}

.slider1{

  margin-bottom: 3%;
  margin-left: 5%;
  margin-right: 5%;
  margin-top: 2%;
}
.slidecontainer {
  width: 100%;
}

.slider {

  background: #d3d3d3;
  -webkit-appearance: none;
  width: 100%;
  height: 6px;
  border-radius: 5px;
  padding-left: 0%;
  padding-bottom: 0%;
  outline: none;
  opacity: 0.7;
  -webkit-transition: .2s;
  transition: opacity .2s;
}

.slider:hover {
  opacity: 1;
}

.slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background:  #e83196;
  cursor: pointer;
}

.slider::-moz-range-thumb {
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background:  #e83196;
  cursor: pointer;
}




p{
  font-size: 30px;
  letter-spacing: 1px;
  line-height: 47px;
  color: #000000;
  font-family: "Barlow-Regular";
  font-weight: 400;
  text-align: center;
}

.App {
  /* text-align: center; */
  display: flex;
  background-color: #fff;
  min-height: 100vh;
  flex-direction: column;
  overflow: hidden;
}


.Appheader {
  width: 99%;
  display: flex;
  justify-content: center;
    background: #fff;
   

 
    box-shadow:10px 10px 15px   #cccccc ;
   
  
}

.Appheader h1{
  font-size: 30px;
  letter-spacing: 1px;
  line-height: 37px;
  color: #000000;
  font-family: "Barlow-Bold";
  font-weight: 700;
}

.Appmain {
  /* text-align: center; */
  display: flex;

  flex-direction: row;
  margin: 1%;
  background-color: #fff ;
  font-size: calc(10px );
  color:#000; ;
  height:auto;
padding-left: 5%;
}
.Appmain1 {
  /* text-align: center; */
  display: flex;
  flex-direction: column;
  width: 50%;
  height: auto;
  
}

.Appcontainer{

display: flex;
flex-direction: column;


  box-shadow:20px 20px 30px 10px  #cccccc ;
  

}
.la{
  display: flex;
  flex-direction: column;
}
.la1{
  display: flex;
  flex-direction: column;
  background-color: #f5f5f5;
}
.la2{
  display: flex;
  flex-direction: column;

}
.LoanAmount {
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  margin-bottom: 0%;
  padding: 2%;
  
 

}

.LoanAmount h1{

  
  font-size: 16px;
  letter-spacing: 1px;

  color: #000000;
  font-family: "Barlow-Regular";
  font-weight: 400;
  text-align: center;
  margin-left: 5%;
}






.my-5 {
  margin-bottom: 1rem!important;
  margin-top: 0rem!important;
}

.inputtypetext{

 
  margin-left: 15%


}


.InterestRate {
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  margin-bottom: 0%;
  padding: 2%;
 
}
.inputtypetext1{
  

  margin-left: 0%;


}


.InterestRate h1{

  font-size: 16px;
  letter-spacing: 1px;

  color: #000000;
  font-family: "Barlow-Regular";
  font-weight: 400;
  text-align: center;
  margin-left: 5%;
}


.LoanTerm {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 2%;
  margin-bottom: 0%;

}
.inputtypetext2{

  margin-left: 7%;

}

 .LoanTerm h1{

  font-size: 16px;
  letter-spacing: 1px;

  color: #000000;
  font-family: "Barlow-Regular";
  font-weight: 400;
  text-align: center;
  margin-left: 5%
}

.btn h6{
  float: left;
  color:#000;;
  font-size: 14px;
  font-family: 'Barlow-Regular';
  font-weight: 500;

}
.xa{

  display: flex;
  flex-direction: column;
  margin-left: 5%;
}
.rx{ width: 2em; height: 2em;}
.rx1{ width: 2em; height: 2em;}

.rad{
  display: flex;
  flex-direction: row;

  margin-left: 6%;
  align-items: center
  
}
.rad0{
  display: flex;
  flex-direction: row;
  margin-top: 0%;
  margin-right: 5%;
  align-items: center;
}
input[type='radio']:after {
 
  background-color: #d1d3d1;
 
  border: 2px solid white;
}


.input-group{
  align-items: center
}
.rad0 h2{
  font-size: 14px;
 
 
  color: #262626;
  font-family: "Barlow-Regular";
  font-weight: 400;
  margin: 0 3%;

}
.rad1{
  display: flex;
  flex-direction: row;
  color:#000;;
  margin-top: 0%;
  
  align-items: center
  
}

.rad1 h2{
  font-size: 14px;

 
  color: #262626;
  font-family: "Barlow-Regular";
  font-weight: 400;
  margin: 0 3%;

}


.btnDiv{
  display: flex;
  
  margin-top: 1%;
  
}
.btn-default {
  background-image: linear-gradient(to right, #d854df ,#f81050) !important;
  color: #fff;
  width: 100%;
  padding: 0%;
}
.btnDiv h1{
  font-size: 20px;
  letter-spacing: 1px;
  color: #ffffff;
  font-family: "Barlow-Regular";
  margin: 0px;
  padding: 6px;
  

}



.form-control{

    background-color: #f3f3f3;

}
.input-group-append {
  margin-left: 0%;
}


.mdb-color.lighten-2 {
  background-color: #3a4360 !important;
}

.custom-range {
  width: 90%;
 
}
.abc{

  display: flex;
  flex-direction: column;

}

.abc h1 {
  font-size: 20px;
  letter-spacing: 1px;

  color: #000000;
  font-family: "Barlow-Regular";
  font-weight: 700;
 
  padding-top: 1%;
}



.piechart{

 
  width: 85vh;

  
 

}

.total h1{
  
  font-size: 16px;
  letter-spacing: 1px;
 
  color: #3d3d3d;
  font-family: "Barlow-Regular";
  font-weight: 400;
 
  text-align: center
}

.total h2{
  
  
  color:#000;
    font-size: 18px;
    font-family: "Barlow-Bold";
    font-weight: 500;
    text-align: center;

}

.btnDiv{
  background-color:'orange';
  width:'100vw';

}
.line{
 
margin-left: 10%;
}



.line hr{

  height: 1px ;
  background-color: #cccccc;
}

.footer{
  display: flex;
  flex-direction: row;

}
.footer h1{
  font-size: 14px;
letter-spacing: 1px;

color: #3d3d3d;
font-family: "Barlow-Regular";
font-weight: 500;
}
.footer span{
  font-size: 16px;
letter-spacing: 1px;
line-height: 18px;
color: #3d3d3d;
font-family: "Barlow-bold";
}
.footer1{
  width: 75%;
  display: flex;
  justify-content: center;
  padding: 1%;
  background: #fff;
  
  margin-left: 1%;
  margin-right: 1%;
  box-shadow: 10px 10px 10px 15px #cccccc;
  align-items: center;

}



.footer1 img{

 
  width: 100px;
  border-radius: 50%;
  margin-right: 5%;

  
}
.footer2{
  width: 45%;
  display: flex;
  justify-content: center;
  padding: 1%;
  background: #fff;
 
  margin-left: 1%;
  margin-right: 1%;
  box-shadow: 10px 10px 10px 15px #cccccc;
  align-items: center;

}


.p1{
  display: flex;
  flex-direction: row;
}
.pp1{
  display: flex;
  flex-direction: column;

}

.pp1 h1{
  font-size: 18px;
letter-spacing: 0px;
line-height: 20px;

color: #3d3d3d;
font-family: "Barlow-Regular";
font-weight: 400;
}
.pp1 h2{
  margin-left: 2%;
  font-size: 15px;
letter-spacing: 0px;
line-height: 15px;

color: #3d3d3d;
font-family: "Barlow-bold";

}



.pp1mobile{

  display: none;
  flex-direction: row;
  align-items: center;
  width: 100%;
  justify-content: space-between;
  padding: 0px 5%;
}








@media (min-width: 768px) and (max-width: 1024px) {
  .abc{
    width:50vw;
  }

  
}





@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
  
  .abc{
    width:50vw;
  }
  
}





@media (min-width: 481px) and (max-width: 767px) {



  .Appmain {
    
    padding-left: 1%;
}


  .Appcontainer {

    width: 46vw;
    height: 51vw;
    margin-top: 4%;
    
    display: flex;
    justify-content: center;
    box-shadow: 6px 6px 13px 10px #cccccc;

  
}

.Appheader h2{
  font-size: 25px;
  font-weight: bold;
   
    
  }

.p1 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;

}

.pp1 h1{
  font-size: 12px;
letter-spacing: 0px;

color: #3d3d3d;
font-family: "Barlow-Regular";
font-weight: 400;
}
.pp1 h2 {
  font-size: 13px;
  letter-spacing: 0px;
  line-height: 15px;
  color: #3d3d3d;
  font-family: "Barlow-Regular";
  font-weight: 600;

}
.LoanAmount {
  
 width: 43vw;
 
}

.InterestRate {
  width: 43vw;
}

.LoanTerm  {
  width: 40vw;
}


.LoanAmount h1 {
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 0px;
  line-height: 0px;
  color: #000000;
  margin-top: 7%

}

.InterestRate h1 {
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 0px;
  color: #000000;
  margin-top: 7%
}

.LoanTerm h1 {
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 0px;
  line-height: 20px;
  color: #000000;
  margin-top: 20%;
}
.i1 {
  width: 64%;
  margin-left: 38%;
}
.i2 {
  width: 50%;
  margin-left: 32%;
}
.total h1{
  
  font-size: 16px;
  letter-spacing: 0px;
  line-height: 0px;
 
 
  
}

.total h2{
  
  font-size: 20px;
  letter-spacing: 0px;
  line-height: 25px;

 
}
.abc  {
 width: 50vw;
 padding-top: 2%;

}
.abc h1 {
  font-size: 15px;

}

.container {
  width: 48vw;
  padding-right: 0px;
  padding-left: 0px;
  margin-right: auto;
  margin-left: auto;
}


.xa {

  margin-left: 0%;}

  .rad {
   
    margin-left: 14%;}




.pp1 {

  margin-top: 0%;
width: 42vw;

}

.pp12{

  display: flex;
  flex-direction: row;

  justify-content:space-between;
}

.p1{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}



.footer1{
  margin-bottom: 1%
}
.footer2{
  margin-bottom: 1%;
  text-align: center;
}

.footer h1 {
  font-size: 12px;
  line-height: 20px;
  letter-spacing: 0px;

 }

  .footer span {
    font-size: 14px;
    letter-spacing: 0px;
    line-height: 22px;}

    .chartjs-render-monitor {
      display: flex;
      height: 204px;
      width: 375px;

    }

}










@media (min-width: 320px) and (max-width: 480px)   {
  .pp1mobile{

    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    justify-content: space-between;
  padding: 0px 5%;
    
}

 
  * {
    box-sizing: border-box;
      
       margin: 0;
      padding:0;

     
  }
 

  .Appheader {
    width: 100%;
    padding-top: 3%
    
  }
  .Appheader h2{
  font-size: 25px;
  font-weight: bold;
   
    
  }


  .Appmain {
   
    flex-direction: column;
    width: 100vh;
    
     
      margin-left: 0%;
      padding-left: 0%;
  }

  .Appmain1{
    width: 100vw;
  }

  .container {
    width: 100vw;
    padding-right: 0px;
    padding-left: 0px;
    margin-right: 0%;
    margin-left: 0%;

    display: flex;
    justify-content: center;
}
  

  .p1{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

  }

  .pp1{

    display: none;
    flex-direction: row;
    align-items: center;
    width: 100%;
    justify-content: space-evenly;
    
}






  .pp1 h1{
    font-size: 12px;
  letter-spacing: 0px;

  color: #3d3d3d;
  font-family: "Barlow-Regular";
  font-weight: 400;
  }
  .pp1 h2 {
    font-size: 13px;
    letter-spacing: 0px;
    line-height: 13px;
    color: #3d3d3d;
    font-family: "Barlow-Regular";
    font-weight: 600;

}


.p4 span{

  display: none;

}


  .Appcontainer {
    display: flex;
    box-shadow: none;
  
}
    .line{
      display: none;
    }
    .abc{
    
      align-items: flex-start;
    box-shadow: 11px 4px 6px 2px #cccccc ;
      width: 100vw;
     
    }
    .abc1{
      display: none;
    }
  
    .my-5 {
      margin-bottom: 1rem!important;
      margin-top: 1rem!important;
  }

    .input-group {
      margin-left: 0%;
      width: 85%;
  }

  .LoanAmount {
  
    width: 100vw;
    display: flex;
    justify-content: space-between;
  }

  .LoanAmount h1 {
    font-size: 16px;
    font-weight: bold;
    letter-spacing: 0px;
    line-height: 0px;
    color: #000000;
    margin-top: 7%
 
}

  .inputtypetext {
    display: flex;
    width: 100vw;
    justify-content: center;
}
  .InterestRate {
    
  
      width: 100vw;
      display: flex;
      justify-content: space-between;

  
  }

  .InterestRate h1 {
    font-size: 16px;
    font-weight: bold;
    letter-spacing: 0px;
    color: #000000;
    margin-top: 7%
}
  .inputtypetext1 {
    display: flex;
    width: 100vw;
    justify-content: center;
}
  .LoanTerm {
   
  
      width: 100vw;
      display: flex;
      justify-content: space-between;
  
   
  }

  .LoanTerm h1 {
    font-size: 16px;
    font-weight: bold;
    letter-spacing: 0px;
    line-height: 0px;
    color: #000000;
    margin-top: 20%;
}

  .inputtypetext2 {
  
    display: flex;
    width: 100vw;
    justify-content: center;
}


.slider {
  margin-top: 0%;
}
.rad{
  margin-top: 10%;
}
.rad h2{
  font-size: 15px;

 
  color: #262626;
  

}

.abc h1 {
  margin-top: 0%;
  font-size: 12px;
  line-height: 20px;
}
.btn-default {
 
 
  width: 100%;
  
  height: 55px;
}
.btnDiv h1 {
  font-size: 25px;
  
}


.piechart{

  width: 60vh;
  margin-top: 2%;
  

} 

.footer{
  display: flex;
  flex-direction: column;
 
}

.footer h1 {
  font-size: 12px;
  line-height: 20px;
  letter-spacing: 0px;

 }

  .footer span {
    font-size: 14px;
    letter-spacing: 0px;
    line-height: 22px;}


    .footer1{
      width: 100vw;
      padding: 0%;
    
      box-shadow:1px 4px 6px 0px #cccccc  ;

      
    }


.footer2{
  width: 100vw;
  display: flex;
  text-align: center;
  padding: 4%;

      
    box-shadow: 0px 4px 11px 0px #cccccc;

}


.total{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
}





.total h1{
  
  font-size: 16px;
  letter-spacing: 0px;
  line-height: 16px;
 
 
  
}

.total h2{
  
  font-size: 20px;
  letter-spacing: 0px;
  line-height: 20px;

 
}

.i1{
  width: 50%;
  margin-left: 28%;
}

.i2{
  width: 40%;
}

.footer1 img{

  width: 100px;
 
  border-radius: 50px;

  margin: 5%;
}
.xa1 {
  
    display: flex;
    width: 100%;
    justify-content:flex-start;

}

}

@media only screen and (min-width: 900px)   {
  .App {
    
    justify-content: space-around;
}
.abc{
  width:50vw;
}


.LoanAmount {
 
padding:8% 8% 8% 8%;
}


.InterestRate {
 
  padding:8% 0% 8% 8%;
}



.LoanTerm {
 
  padding:8% 0% 8% 8%;

}





.abc1 {

  padding-top: 4%;
}

.abc1 span h1{
  margin-left: 6%;
  padding-top: 4%;
}
.piechart {
  width: 100vw;
}
.pp1 {

  padding-right: 7%;
  margin-top: 7%
}


.container{
  margin-top: 7%;
}
.total{
  display: flex;
  justify-content: center;
  flex-direction: column;
}


.total h1 {
  font-size: 26px;
  line-height: 36px;

}

.total h2 {
  color: #000;
  font-size: 30px;

}
 
}












@media only screen and (min-width: 1100px)   {

  .LoanAmount {
 
    padding:3%;
    }
    
    
    .InterestRate {
     
      padding:3%;
   
    }
    
    
    
    .LoanTerm {
      padding:3%;
    }




    .abc1 {

      padding-top: 2%;
    }
    
    .abc1 span h1{
    
      padding-top: 0%;
    }

    .pp1 {

      padding-right: 7%;
      margin-top: 0%
    }
    
    
    .container{
      margin-top: 0%;
    }
 
    
  
}


@media only screen and (min-width: 1300px)  {

  .App {
    
    justify-content: space-around;
}

}



/* Large screens ----------- */
@media only screen  and (min-width : 1800px)  {


  .app{

    justify-content: space-evenly;
  }
  .Appmain {
   
    padding-left: 3%;
}


.Appcontainer {
  height: 55vh;
}
.btnDiv {
  height: 4vw;
}
.LoanAmount {
 
  padding: 5%;
}
.LoanAmount h1 {
  font-size: 26px;
 
}

.InterestRate {
 
  padding: 5%;
}

.InterestRate h1 {
  font-size: 26px;
 
}

.LoanTerm {
 
  padding: 3%;

}

.LoanTerm h1 {
  font-size: 26px;
  line-height: 45px;
 
}

.abc1 {

  padding-top: 4%;
}

.abc1 span h1{
  margin-left: 6%;
  padding-top: 4%;
}
.piechart {
  width: 70vh;
}
.pp1 {

  padding-right: 10%;
}
.pp1 h1 {
  font-size: 26px;
  letter-spacing: 0px;
  line-height: 32px;}
  p1 h2 {
   
    font-size: 26px;
    letter-spacing: 0px;
    line-height: 30px;
  }

.container{
  margin-top: 7%;
}
.total{
  display: flex;
  justify-content: center;
  flex-direction: column;
}


.total h1 {
  font-size: 26px;
  line-height: 36px;

}

.total h2 {
  color: #000;
  font-size: 30px;

}



.footer h1 {
  font-size: 30px;
  line-height: 40px;
  
}

.footer span {
  font-size: 35px;
  letter-spacing: 1px;
  line-height: 48px;

}
}